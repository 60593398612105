import React from "react";

export default (props: any) => {
    return (
        <div
            style={{
                width: props.width || "20px",
                height: props.height || "20px",
                boxSizing: "border-box",
                paddingTop: props.top || "",
                paddingLeft: props.left || "",
                paddingRight: props.right || "",
                paddingBottom: props.bottom || "",
                marginTop: props.mt || "",
            }}
        >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 17"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>表格</title>
                <g id="页面-1" stroke="none" fill="none">
                    <g id="sheet" transform="translate(1.056465, 1.192818)">
                        <path
                            d="M11.5507406,3.314 C11.7757406,3.579 11.8387406,3.658 11.8827406,3.74 C11.9273495,3.82299089 11.9600058,3.91186975 11.9797406,4.004 C11.9997406,4.095 12.0017406,4.196 12.0017406,4.544 L12.0017406,13.1 C12.0017406,13.528 12.0017406,13.82 11.9827406,14.045 C11.9647406,14.264 11.9327406,14.375 11.8927406,14.455 C11.796699,14.642782 11.6437421,14.7953888 11.4557406,14.891 C11.3777406,14.931 11.2657406,14.963 11.0457406,14.981 C10.8207406,15 10.5297406,15.001 10.1017406,15.001 L1.90174059,15.001 C1.47374059,15.001 1.18174059,15 0.957740594,14.981 C0.737740594,14.963 0.625740594,14.931 0.547740594,14.891 C0.359586898,14.7951281 0.206612514,14.6421537 0.110740594,14.454 C0.0707405937,14.376 0.0387405937,14.264 0.0207405937,14.044 C0.00291120556,13.7296698 -0.003426356,13.4147931 0.00174059369,13.1 L0.00174059369,1.9 C0.00174059369,1.472 0.00174059369,1.18 0.0207405937,0.956 C0.0387405937,0.736 0.0707405937,0.624 0.110740594,0.546 C0.206782226,0.358217988 0.359739048,0.205611181 0.547740594,0.11 C0.625740594,0.07 0.737740594,0.038 0.957740594,0.02 C1.18174059,0 1.47374059,0 1.90174059,0 L7.86074059,0 C8.29574059,0 8.42374059,0.004 8.53474059,0.034 C8.64668558,0.0638374072 8.75259431,0.112900559 8.84774059,0.179 C8.94274059,0.245 9.02774059,0.339 9.30974059,0.671 L11.5397406,3.301 L11.5507406,3.314 Z"
                            id="路径"
                            stroke="#3EC263"
                        ></path>
                        <rect
                            id="矩形"
                            fill="#3EC263"
                            x="2.60174059"
                            y="4.5"
                            width="2.8"
                            height="2.8"
                            rx="0.5"
                        ></rect>
                        <rect
                            id="矩形"
                            fill="#3EC263"
                            x="6.60174059"
                            y="4.5"
                            width="2.8"
                            height="2.8"
                            rx="0.5"
                        ></rect>
                        <rect
                            id="矩形"
                            fill="#3EC263"
                            x="2.60174059"
                            y="8.5"
                            width="2.8"
                            height="2.8"
                            rx="0.5"
                        ></rect>
                        <rect
                            id="矩形"
                            fill="#3EC263"
                            opacity="0.7"
                            x="6.60174059"
                            y="8.5"
                            width="2.8"
                            height="2.8"
                            rx="0.5"
                        ></rect>
                    </g>
                </g>
            </svg>
        </div>
    );
};
