import React from "react";

export default (props: any) => {
    return (
        <div
            style={{
                width: props.width || "20px",
                height: props.height || "20px",
                boxSizing: "border-box",
                paddingTop: props.top || "",
                paddingLeft: props.left || "",
                paddingRight: props.right || "",
                paddingBottom: props.bottom || "",
            }}
        >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 14 17"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>ppt</title>
                <g id="页面-1" stroke="none" strokeWidth="1" fill="none">
                    <g id="ppt" transform="translate(0.599194, 1.192818)">
                        <path
                            d="M9.30974059,0.671 L11.5397406,3.301 L11.5507406,3.314 C11.7757406,3.579 11.8387406,3.658 11.8827406,3.74 C11.9273495,3.82299089 11.9600058,3.91186975 11.9797406,4.004 C11.9997406,4.095 12.0017406,4.196 12.0017406,4.544 L12.0017406,13.1 C12.0017406,13.528 12.0017406,13.82 11.9827406,14.045 C11.9647406,14.264 11.9327406,14.375 11.8927406,14.455 C11.796699,14.642782 11.6437421,14.7953888 11.4557406,14.891 C11.3777406,14.931 11.2657406,14.963 11.0457406,14.981 C10.8207406,15 10.5297406,15.001 10.1017406,15.001 L1.90174059,15.001 C1.47374059,15.001 1.18174059,15 0.957740594,14.981 C0.737740594,14.963 0.625740594,14.931 0.547740594,14.891 C0.359586898,14.7951281 0.206612514,14.6421537 0.110740594,14.454 C0.0707405937,14.376 0.0387405937,14.264 0.0207405937,14.044 C0.00291120556,13.7296698 -0.003426356,13.4147931 0.00174059369,13.1 L0.00174059369,1.9 C0.00174059369,1.472 0.00174059369,1.18 0.0207405937,0.956 C0.0387405937,0.736 0.0707405937,0.624 0.110740594,0.546 C0.206782226,0.358217988 0.359739048,0.205611181 0.547740594,0.11 C0.625740594,0.07 0.737740594,0.038 0.957740594,0.02 C1.18174059,0 1.47374059,0 1.90174059,0 L7.86074059,0 C8.29574059,0 8.42374059,0.004 8.53474059,0.034 C8.64668558,0.0638374072 8.75259431,0.112900559 8.84774059,0.179 C8.94274059,0.245 9.02774059,0.339 9.30974059,0.671 Z"
                            id="路径"
                            stroke="#FF5E3C"
                        ></path>
                        <path
                            d="M5.45874059,4.629 C5.4611934,4.54492989 5.42572542,4.46421017 5.36214194,4.40915619 C5.29855847,4.35410221 5.21359519,4.33054608 5.13074059,4.345 C3.27361573,4.69888067 1.96986407,6.37920462 2.08844634,8.26602271 C2.20702862,10.1528408 3.71089979,11.656712 5.59771788,11.7752943 C7.48453597,11.8938765 9.16485992,10.5901249 9.51874059,8.733 C9.55174059,8.559 9.41274059,8.405 9.23474059,8.405 L5.75874059,8.405 C5.67917565,8.405 5.60286947,8.37339295 5.54660856,8.31713203 C5.49034765,8.26087112 5.45874059,8.18456495 5.45874059,8.105 L5.45874059,4.629 Z"
                            id="路径"
                            fill="#FF5E3C"
                        ></path>
                        <path
                            d="M9.88174059,7.33 C9.89414857,7.4125462 9.86948145,7.49635968 9.8143365,7.5590244 C9.75919155,7.62168911 9.67919446,7.65681222 9.59574059,7.655 L6.50774059,7.655 C6.42817565,7.655 6.35186947,7.62339295 6.29560856,7.56713203 C6.23934765,7.51087112 6.20774059,7.43456495 6.20774059,7.355 L6.20774059,4.246 C6.20774059,4.07 6.36074059,3.93 6.53474059,3.961 C8.24906165,4.26614107 9.58783736,5.61371662 9.88174059,7.33 Z"
                            id="路径"
                            fill="#FF5E3C"
                            opacity="0.7"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};
